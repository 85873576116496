<div class="main-banner item-bg-one" id="home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <div class="title"><a href="https://kanzlei-zahner-konstanz.de" target="_blank">Rechtsanwalt Gerhard Zahner</a> <br/>
                        <div style="margin-top: 8px">{{'banner-sub-title' | transloco}}</div>
                        <div class="title">
                            <h1><span>WHISTLEBLOWER</span> INVESTIGATIONS</h1>
                        </div>
                        <div [innerHTML]="'cooperation-banner-text' | transloco"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
