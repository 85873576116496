<div class="nas-cookie-banner flex-column flex-md-row gap-2 justify-content-between align-items-center px-5" [class.hidden]="fadeOut" [class.d-none]="hideCocki">
    <div>{{'cookie-description' | transloco}} <a class="link-light text-decoration-underline" routerLink="/datenschutz">{{'privacy-policy' | transloco}}</a></div>
    <div class="row gap-2">
        <button class="btn btn-outline-light w-auto mr-4"(click)="setCookieConsent('denied', content)">{{'cookie-decline-button' | transloco}}</button>
        <button class="btn btn-light w-auto" (click)="setCookieConsent('accepted', content)">{{'cookie-accept-button' | transloco}}</button>
    </div>
</div>
<div class="nas-cookie-banner-change-choice justify-content-between align-items-center" [class.d-none]="status === 'accepted'" (click)="openCookieBanner()">
    <i class="fa-solid fa-cookie-bite"></i>
</div>
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'cookie-error-title' | transloco}}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <p>{{'cookie-error-message' | transloco}}</p>
    </div>
</ng-template>
