import {Component, OnDestroy, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {ContactService} from 'src/app/services/contact/contact.service';
import {CookieService} from "ngx-cookie-service";
import {translate} from "@ngneat/transloco";
import {FormGroup, NgForm} from "@angular/forms";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {

    currentCookie: string = '';
    showMailResponse = false;
    showError = false;
    mailResponse = "asdfsd fasdfsdfsdf"
    contactForm: FormGroup

    constructor(private contactService: ContactService, private viewportScroller: ViewportScroller, private cookieService: CookieService) {
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
        window.setInterval(() => {
            this.currentCookie = this.cookieService.get('cocki'); // 'static' memory between function calls
        }, 100);
    }

    ngOnDestroy() {
    }

    submit(ngForm) {

        const form = ngForm.value;

        this.contactService.sendEmail(form.name, form.email, form.number, form.subject, form.message)
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                ngForm.reset();
                this.mailResponse = translate("contact-form-response-successful")
                this.showMailResponse = true;
                this.showError = false;
            }, (error) => {
                console.log('FAILED...', error);
                this.mailResponse = translate("contact-form-response-failure")
                this.showMailResponse = true;
                this.showError = true;
            });
    }

    clearForm() {

    }

}
