<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>{{'welcome-title' | transloco}}</h2>

        </div>
        <div class="row align-items-center welcome-text-container gap-3 gap-lg-0 flex-lg-row-reverse">
            <div class="col-lg-6 col-12">
                <div class="welcome-text">
                    <img src="assets/img/welcome-1.webp" alt="{{'welcome-alt-text' | transloco}}">
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <div class="about-content">
                    <p [innerHTML]="'welcome-content-1' | transloco"></p>
                </div>
            </div>
        </div>
        <div class="row align-items-center welcome-text-container gap-3 gap-lg-0">
            <div class="col-lg-6 col-12">
                <div class="welcome-text">
                    <img src="assets/img/welcome-2.webp" alt="{{'welcome-alt-text' | transloco}}">
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <div class="about-content">
                    <p>{{'welcome-content-2' | transloco}}</p>
                </div>
            </div>
        </div>
        <div class="row align-items-center welcome-text-container gap-3 gap-lg-0 flex-lg-row-reverse">
            <div class="col-lg-6 col-12">
                <div class="welcome-text">
                    <img src="assets/img/welcome-3.webp" alt="{{'welcome-alt-text' | transloco}}">
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <div class="about-content">
                    <p>{{'welcome-content-3' | transloco}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="{{'shape-alt-text' | transloco}}"></div>
</div>
