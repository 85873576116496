import {Injectable} from '@angular/core';
import * as emailjs from 'emailjs-com';
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    constructor() {
    }

    sendEmail(fromName: string, fromMail: string, fromPhone: string, subject: string, body: string): Promise<any> {
        let template = {
            from_name: fromName,
            from_mail: fromMail,
            from_phone: fromPhone,
            subject: subject,
            message: body
        };
        return emailjs.send('service_d1p1it2', 'template_9xi055n', template, 'j5FBZCCSTap4k5_in');
    }
}
