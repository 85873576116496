<app-main-banner></app-main-banner>

<app-welcome></app-welcome>

<app-services></app-services>

<app-advantages></app-advantages>

<app-team></app-team>

<app-competence></app-competence>

<app-pricing></app-pricing>

<app-contact></app-contact>
