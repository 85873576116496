<div class="strategy-area" id="advantages">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/vorteile.webp" width="1025" height="625" alt="{{'navigation-advantages' | transloco}}">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h2 [innerHTML]="'advantage-title' | transloco"></h2>
                        <p>{{'advantage-first-text' | transloco}}</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fa fa-check"></i>{{'first-advantage' | transloco}}</li>
                        <li><i class="fa fa-check"></i>{{'second-advantage' | transloco}}</li>
                        <li><i class="fa fa-check"></i>{{'third-advantage' | transloco}}</li>
                    </ul>
                    <p [innerHTML]="'advantage-second-text' | transloco"></p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="{{'shape-alt-text' | transloco}}"></div>
</div>
