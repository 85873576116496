import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cocki',
  templateUrl: './cocki.component.html',
  styleUrls: ['./cocki.component.scss']
})
export class CockiComponent implements OnInit {

    hideCocki = false;
    fadeOut = false;
    showModal = false;
    status = 'denied';

    constructor(private cookieService: CookieService, private modalService: NgbModal) {}

    ngOnInit() {
        this.hideCocki = this.cookieService.check('cocki');
        this.fadeOut = this.cookieService.check('cocki');
        this.status = this.cookieService.get('cocki');
    }

    setCookieConsent(status: string, modal) {
        const aYearFromNow = new Date();
        aYearFromNow.setMonth(aYearFromNow.getMonth() + 6);
        this.cookieService.set('cocki', status, {expires: aYearFromNow, sameSite: "Strict", domain: window.location.hostname})
        this.status = this.cookieService.get('cocki');
        if (!this.cookieService.check('cocki')) {
            this.showModal = true
            this.open(modal)
        } else {
            this.fadeOut = true;
            this.hideCockiBanner()
        }
    }

    openCookieBanner() {
        this.hideCocki = false;
        this.fadeOut = false;
    }

    private hideCockiBanner() {
        setTimeout(() => {
            this.hideCocki = true
        }, 1000)
    }

    open(modal) {
        this.modalService.open(modal)
    }
}
