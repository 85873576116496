import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationCancel, NavigationEnd, Router, RouterState} from '@angular/router';
import {DOCUMENT, Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {Meta, Title} from "@angular/platform-browser";
declare const gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})

export class AppComponent implements OnInit, OnDestroy {
    location: any;
    routerSubscription: any;
    consentKey = 'cookieConsent';

    //keep refs to subscriptions to be able to unsubscribe later
    private statusChangeSubscription!: Subscription;
    private revokeChoiceSubscription!: Subscription;

    constructor(
        private router: Router,
        private titleService: Title,
        private metaService: Meta,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.handleRouteEvents();

    }

    ngOnInit() {

        this.recallJsFuntions();

        // this.handleConsentChanges(this.cookieService.get(this.consentKey))
    }

    handleRouteEvents() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // Set Meta Data
                const title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
                const description = this.getDescription(this.router.routerState, this.router.routerState.root).join('-');
                const canonicalUrl = this.getCanonicalUrl(this.router.routerState, this.router.routerState.root).join('-');

                this.titleService.setTitle(title);
                this.metaService.addTag({name: 'description', content: description});
                this.metaService.addTag({name: 'og:title', content: title});
                this.metaService.addTag({name: 'og:url', content: this.document.location.href});
                this.updateCanonicalUrl(`${window.location.origin}${canonicalUrl}`);

                // if (this.cookieService.check(this.consentKey) && this.cookieService.get(this.consentKey) === 'allow') {
                //     this.sendGoogleAnalyticsEvent(title, event.urlAfterRedirects)
                // }
            }
        });
    }

    getTitle(state: RouterState, parent: ActivatedRoute): string[] {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
            data.push(parent.snapshot.data['title']);
        }
        if (state && parent && parent.firstChild) {
            data.push(...this.getTitle(state, parent.firstChild));
        }
        return data;
    }

    getDescription(state: RouterState, parent: ActivatedRoute): string[] {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data['description']) {
            data.push(parent.snapshot.data['description']);
        }
        if (state && parent && parent.firstChild) {
            data.push(...this.getDescription(state, parent.firstChild));
        }
        return data;
    }

    getCanonicalUrl(state: RouterState, parent: ActivatedRoute): string[] {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data['canonicalUrl']) {
            data.push(parent.snapshot.data['canonicalUrl']);
        }
        if (state && parent && parent.firstChild) {
            data.push(...this.getCanonicalUrl(state, parent.firstChild));
        }
        return data;
    }

    // enableGoogleAnalyticsTracking() {
    //     const gaTrackingId = environment.ga; // Replace with your Google Analytics Tracking ID
    //
    //     // Add the Google Analytics script dynamically
    //     const scriptElement = this.document.createElement('script');
    //     scriptElement.async = true;
    //     scriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`;
    //     this.document.head.appendChild(scriptElement);
    //
    //     // Configure Google Analytics
    //     gtag('js', new Date());
    //     gtag('config', gaTrackingId);
    //
    //     this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
    // }
    //
    // sendGoogleAnalyticsEvent(title: string, path: string) {
    //     gtag('event', 'page_view', {
    //         page_title: title,
    //         page_path: path,
    //         page_location: this.document.location.href
    //     })
    // }
    //
    // disableGoogleAnalyticsTracking() {
    //     // Remove the Google Analytics script from the document
    //     const scriptElement = this.document.querySelector(`script[src^="https://www.googletagmanager.com/gtag/js"]`);
    //     if (scriptElement) {
    //         scriptElement.remove();
    //     }
    //
    //     // Clear Google Analytics cookies
    //     const cookies = ['_ga', '_gid']; // Add any other Google Analytics cookies you want to clear
    //     cookies.forEach((cookie) => {
    //         this.cookieService.delete(cookie);
    //     });
    // }
    //
    // handleConsentChanges(status) {
    //         if (status === 'allow') {
    //             this.enableGoogleAnalyticsTracking();
    //         } else {
    //             this.disableGoogleAnalyticsTracking();
    //         }
    // }

    ngOnDestroy(): void {
        this.statusChangeSubscription.unsubscribe()
        this.revokeChoiceSubscription.unsubscribe()
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    updateCanonicalUrl(url: string) {
        const head = this.document.getElementsByTagName('head')[0];
        var element: HTMLLinkElement = this.document.querySelector(`link[rel='canonical']`) || null
        if (element === null) {
            element = this.document.createElement('link') as HTMLLinkElement;
            head.appendChild(element);
        }
        element.setAttribute('rel', 'canonical')
        element.setAttribute('href', url)
    }
}
