<footer class="footer-area">
    <div class="container">
        <img src="assets/img/logo-white.png" alt="Notice Advice Solutions GbR" href=""/>
        <p><i class="far fa-envelope"></i><a href="mailto:&#097;&#110;&#119;&#097;&#108;&#116;&#064;&#108;&#097;&#119;&#045;&#122;&#097;&#104;&#110;&#101;&#114;&#046;&#100;&#101;">anwalt@law-zahner.de</a></p>
        <p><i class="fas fa-phone"></i><a href="tel:+497531128280">Telefon +49 75 31/12 82 80</a></p>
        <p><i class="fas fa-desktop"></i><a href="https://kanzlei-zahner-konstanz.de">www.kanzlei-zahner-konstanz.de</a></p>
        <p><i class="fas fa-building"></i>Wessenbergstraße 2, 78462 Konstanz, {{'germany' | transloco}}</p>
        <p>{{'further-information-footer-text' | transloco}}
            <a href="https://kanzlei-zahner-konstanz.de/" target="_blank">Rechtsanwalt Gerhard Zahner</a>
        </p>
    </div>
</footer>
<ngx-scrolltop></ngx-scrolltop>
