<nav
    class="navbar navbar-expand-lg navbar-light bg-light"
    [class.active]="classApplied"
    [ngClass]="{'sticky': isSticky}"
>
    <div class="container">
        <img id="logo" src="assets/img/logo.png" alt="Notice Advice Solutions GbR" href=""/>
        <button aria-label="Menü öffnen" class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ng-container *ngIf="currentPath === '/'; else subPages">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item"><span class="nav-link" (click)="onClick('home')">Home</span></li>
                    <li class="nav-item"><span class="nav-link"
                                               (click)="onClick('welcome')">{{'navigation-welcome' | transloco}}</span>
                    </li>
                    <li class="nav-item"><span class="nav-link"
                                               (click)="onClick('services')">{{'navigation-services' | transloco}}</span>
                    </li>
                    <li class="nav-item"><span class="nav-link"
                                               (click)="onClick('advantages')">{{'navigation-advantages' | transloco}}</span>
                    </li>
                    <li class="nav-item"><span class="nav-link"
                                               (click)="onClick('team')">{{'navigation-team' | transloco}}</span></li>
                    <li class="nav-item"><span class="nav-link"
                                               (click)="onClick('competence')">{{'navigation-competence' | transloco}}</span>
                    </li>
                    <li class="nav-item"><span class="nav-link"
                                               (click)="onClick('offers')">{{'navigation-offers' | transloco}}</span>
                    </li>
                    <li class="nav-item"><span class="nav-link"
                                               (click)="onClick('contact')">{{'navigation-contact' | transloco}}</span>
                    </li>
                </ul>
                <ul class="navbar-nav ml-auto for-responsive">
                    <li class="nav-item"><span class="nav-link" (click)="onClick('home')">Home</span></li>
                    <li class="nav-item"><span class="nav-link"
                                               (click)="onClick('welcome')">{{'navigation-welcome' | transloco}}</span>
                    </li>
                    <li class="nav-item"><span class="nav-link"
                                               (click)="onClick('services')">{{'navigation-services' | transloco}}</span>
                    </li>
                    <li class="nav-item"><span class="nav-link"
                                               (click)="onClick('advantages')">{{'navigation-advantages' | transloco}}</span>
                    </li>
                    <li class="nav-item"><span class="nav-link"
                                               (click)="onClick('team')">{{'navigation-team' | transloco}}</span></li>
                    <li class="nav-item"><span class="nav-link"
                                               (click)="onClick('competence')">{{'navigation-competence' | transloco}}</span>
                    </li>
                    <li class="nav-item"><span class="nav-link"
                                               (click)="onClick('offers')">{{'navigation-offers' | transloco}}</span>
                    </li>
                    <li class="nav-item"><span class="nav-link"
                                               (click)="onClick('contact')">{{'navigation-contact' | transloco}}</span>
                    </li>
                </ul>
            </ng-container>

            <ng-template #subPages>
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item"><a class="nav-link subpage" routerLink="/">Home</a></li>
                </ul>
                <ul class="navbar-nav ml-auto for-responsive">
                    <li class="nav-item"><a class="nav-link subpage" routerLink="/">Home</a></li>
                </ul>
            </ng-template>
        </div>
    </div>
</nav>
