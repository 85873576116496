<div class="subfooter-area">
    <div class="container">
        <div class="row">
            <div class="col-6">
                <p>© {{ today | date:'yyyy' }} Rechtsanwalt Gerhard Zahner</p>
            </div>
            <div class="col-6 link-area">
                <p><a routerLink="/impressum">{{'imprint' | transloco}}</a> | <a
                    routerLink="/datenschutz">{{'privacy-policy' | transloco}}</a></p>
            </div>
        </div>
    </div>
</div>
