import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {ImprintComponent} from './components/imprint/imprint.component';
import {DataPrivacyComponent} from "./components/data-privacy/data-privacy.component";

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        data: { title: 'Notice Advice Solutions - Hinweisgeberschutzgesetz Lösung', description: 'Die Notice advice solutions GbR steht Ihnen bei der Beratung für das Hinweisgeberschutzgesetz, Rechtsanwalt als Interne Meldestelle zur Seite.', canonicalUrl: '/' }
    },
    {
        path: 'impressum',
        component: ImprintComponent,
        data: { title: 'Notice Advice Solutions - Impressum', description: 'Die Notice advice solutions GbR steht Ihnen bei der Beratung für das Hinweisgeberschutzgesetz, Rechtsanwalt als Interne Meldestelle zur Seite.', canonicalUrl: '/impressum' }
    },
    {
        path: 'datenschutz',
        component: DataPrivacyComponent,
        data: { title: 'Notice Advice Solutions - Datenschutz', description: 'Die Notice advice solutions GbR steht Ihnen bei der Beratung für das Hinweisgeberschutzgesetz, Rechtsanwalt als Interne Meldestelle zur Seite.', canonicalUrl: '/datenschutz'  }
    },
    // Here add new pages component

    {
        path: '**',
        component: HomeComponent,
        data: { title: 'Notice Advice Solutions - Hinweisgeberschutzgesetz Lösung', description: 'Die Notice advice solutions GbR steht Ihnen bei der Beratung für das Hinweisgeberschutzgesetz, Rechtsanwalt als Interne Meldestelle zur Seite.', canonicalUrl: '/'  }
    } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
