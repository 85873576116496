import {Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {translate} from "@ngneat/transloco";

@Component({
    selector: 'app-advantages',
    templateUrl: './advantages.component.html',
    styleUrls: ['./advantages.component.scss']
})
export class AdvantagesComponent implements OnInit {

    constructor(private viewportScroller: ViewportScroller) {
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
    }

    protected readonly translate = translate;
}
