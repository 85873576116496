<div class="faq-area ptb-100" id="competence">
    <div class="container">
        <div class="section-title">
            <h2 [innerHTML]="'competence-title' | transloco"></h2>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="faq-accordion">
                    <div class="accordion">
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(0)"
                                 [ngClass]="{ 'open': isSectionOpen(0) }">
                                {{'first-competence-title' | transloco}}
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(0) }">
                                <p>{{'first-competence-content' | transloco}}</p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(1)"
                                 [ngClass]="{ 'open': isSectionOpen(1) }">
                                {{'second-competence-title' | transloco}}
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(1) }">
                                <p>{{'second-competence-content' | transloco}}</p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(2)"
                                 [ngClass]="{ 'open': isSectionOpen(2) }">
                                {{'third-competence-title' | transloco}}
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(2) }">
                                <p>{{'third-competence-content' | transloco}}</p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(3)"
                                 [ngClass]="{ 'open': isSectionOpen(3) }">
                                {{'fourth-competence-title' | transloco}}
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(3) }">
                                <p>{{'fourth-competence-content' | transloco}}</p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(4)"
                                 [ngClass]="{ 'open': isSectionOpen(4) }">
                                {{'fifth-competence-title' | transloco}}
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(4) }">
                                <p>{{'fifth-competence-content' | transloco}}</p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(5)"
                                 [ngClass]="{ 'open': isSectionOpen(5) }">
                                {{'sixth-competence-title' | transloco}}
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(5) }">
                                <p>{{'sixth-competence-content' | transloco}}</p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(6)"
                                 [ngClass]="{ 'open': isSectionOpen(6) }">
                                {{'seventh-competence-title' | transloco}}
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(6) }">
                                <p>{{'seventh-competence-content' | transloco}}</p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(7)"
                                 [ngClass]="{ 'open': isSectionOpen(7) }">
                                {{'eighth-competence-title' | transloco}}
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(7) }">
                                <p>{{'eighth-competence-content' | transloco}}</p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(8)"
                                 [ngClass]="{ 'open': isSectionOpen(8) }">
                                {{'ninth-competence-title' | transloco}}
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(8) }">
                                <p>{{'ninth-competence-content' | transloco}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
