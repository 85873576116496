<div class="welcome-area pt-150 pb-70">
    <div class="container">
        <div class="section-title">
            <h1>{{'privacy-policy' | transloco}}</h1>
        </div>
        <div class="row align-items-center welcome-text-container">
            <div class="col-12">
                <div class="about-content">
                    <h4 class="data-privacy-area-title">{{'privacy-policy-person-in-charge' | transloco}}</h4>
                    <p>{{'privacy-policy-person-in-charge-content' | transloco}}</p>
                    <h4 class="data-privacy-area-title">{{'privacy-policy-general-data-processing' | transloco}}</h4>
                    <p>{{'privacy-policy-general-data-processing-content' | transloco}}</p>
                    <h4 class="data-privacy-area-title">{{'privacy-policy-logfiles' | transloco}}</h4>
                    <p>{{'privacy-policy-logfiles-content' | transloco}}</p>
                    <h4 class="data-privacy-area-title">{{'privacy-policy-cookies' | transloco}}</h4>
                    <p>{{'privacy-policy-cookies-content' | transloco}}</p>
                    <h4 class="data-privacy-area-title">{{'privacy-policy-google-analytics' | transloco}}</h4>
                    <p>{{'privacy-policy-google-analytics-content' | transloco}}</p>
                    <h4 class="data-privacy-area-title">{{'privacy-policy-contact' | transloco}}</h4>
                    <p>{{'privacy-policy-contact-content-1' | transloco}}</p>
                    <p>{{'privacy-policy-contact-content-2' | transloco}}</p>
                    <h4 class="data-privacy-area-title">{{'privacy-policy-data-subject' | transloco}}</h4>
                    <p>{{'privacy-policy-data-subject-content-1-1' | transloco}}</p>
                    <ul>
                        <li>{{'privacy-policy-data-subject-content-1-item-1' | transloco}}</li>
                        <li>{{'privacy-policy-data-subject-content-1-item-2' | transloco}}</li>
                        <li>{{'privacy-policy-data-subject-content-1-item-3' | transloco}}</li>
                        <li>{{'privacy-policy-data-subject-content-1-item-4' | transloco}}</li>
                    </ul>
                    <p>{{'privacy-policy-data-subject-content-1-2' | transloco}}</p>
                    <ul>
                        <li>{{'privacy-policy-data-subject-content-2-item-1' | transloco}}</li>
                        <li>{{'privacy-policy-data-subject-content-2-item-2' | transloco}}</li>
                    </ul>
                    <h4 class="data-privacy-area-title">{{'privacy-policy-up-to-dateness' | transloco}}</h4>
                    <p>{{'privacy-policy-up-to-dateness-content' | transloco}}</p>
                </div>
            </div>
        </div>
    </div>
</div>




