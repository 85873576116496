import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxScrollTopModule} from 'ngx-scrolltop';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {FormsModule} from '@angular/forms';
import {CountUpModule} from 'ngx-countup';
import {NgxTypedJsModule} from 'ngx-typed-js';


import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './components/home/home.component';
import {NavbarComponent} from './components/common/navbar/navbar.component';
import {WelcomeComponent} from './components/common/welcome/welcome.component';
import {AdvantagesComponent} from './components/common/advantages/advantages.component';
import {TeamComponent} from './components/common/team/team.component';
import {ServicesComponent} from './components/common/services/services.component';
import {FooterComponent} from './components/common/footer/footer.component';
import {CompetenceComponent} from './components/common/competence/competence.component';
import {PricingComponent} from './components/common/pricing/pricing.component';
import {ContactComponent} from './components/common/contact/contact.component';
import {ImprintComponent} from './components/imprint/imprint.component';
import {MainBannerComponent} from './components/common/main-banner/main-banner.component';
import {ContactService} from './services/contact/contact.service';
import {SubfooterComponent} from './components/common/subfooter/subfooter.component';
import {TranslocoRootModule} from './transloco-root.module';
import {TranslocoModule} from "@ngneat/transloco";
import {DataPrivacyComponent} from './components/data-privacy/data-privacy.component';
import {HttpClientModule} from "@angular/common/http";
import {CookieService} from "ngx-cookie-service";
import {CockiComponent} from "./components/cocki/cocki.component";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        NavbarComponent,
        WelcomeComponent,
        AdvantagesComponent,
        TeamComponent,
        ServicesComponent,
        FooterComponent,
        CompetenceComponent,
        PricingComponent,
        ContactComponent,
        MainBannerComponent,
        SubfooterComponent,
        ImprintComponent,
        DataPrivacyComponent,
        CockiComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgxScrollTopModule,
        CarouselModule,
        FormsModule,
        CountUpModule,
        TranslocoModule,
        NgxTypedJsModule,
        HttpClientModule,
        TranslocoRootModule,
        NgbModule
    ],
    providers: [ContactService, CookieService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
