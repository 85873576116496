<div class="who-we-are-area pt-100 pb-70 bg-F7F5F4" id="team">
    <div class="container">
        <div class="section-title">
            <h2 [innerHTML]="'team-title' | transloco"></h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-users"></i>
                    <h3>{{'expertise' | transloco}}</h3>
                    <p>{{'expertise-content' | transloco}}</p>
                    <span>1</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-lightbulb"></i>
                    <h3>{{'multidisciplinarity' | transloco}}</h3>
                    <p>{{'multidisciplinarity-content' | transloco}}</p>
                    <span>2</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-tag"></i>
                    <h3>{{'versatility' | transloco}}</h3>
                    <p>{{'versatility-content' | transloco}}</p>
                    <span>3</span>
                </div>
            </div>
        </div>
    </div>
</div>
