<div id="offers" class="pricing-area pt-100 pb-70 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2 [innerHTML]="'pricing-title' | transloco"></h2>
<!--            <p>{{'pricing-subtitle' | transloco}}</p>-->
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">{{'service-fee' | transloco}}</h3>
                    <div class="price-value">
                        <span class="currency">{{'protection-fees' | transloco}}</span>
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i>{{'protection-fee-1' | transloco}}</li>
                        <li><i class="fas fa-check"></i>{{'protection-fee-2' | transloco}}</li>
                        <li><i class="fas fa-check"></i>{{'protection-fee-3' | transloco}}</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">{{'hint' | transloco}}</h3>
                    <div class="price-value">
                        <span class="currency">{{'hint-without-action' | transloco}}</span>
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i> {{'hint-without-action-1' | transloco}}</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">{{'hint' | transloco}}</h3>
                    <div class="price-value">
                        <span class="currency">{{'hint-with-action' | transloco}}</span>
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i>{{'hint-with-action-1' | transloco}}</li>
                        <li><i class="fas fa-check"></i>{{'hint-with-action-2' | transloco}}</li>
                        <li><i class="fas fa-check"></i>{{'hint-with-action-3' | transloco}}</li>
                        <li><i class="fas fa-check"></i>{{'hint-with-action-4' | transloco}}</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">{{'presentation' | transloco}}</h3>
                    <div class="price-value">
                        <span class="currency">{{'presentation-price-value' | transloco}}</span>
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i>{{'presentation-1' | transloco}}</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">{{'trainings' | transloco}}</h3>
                    <div class="price-value">
                        <span class="currency">{{'trainings-price-value' | transloco}}</span>
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i>{{'trainings-1' | transloco}}</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">{{'expenses' | transloco}}</h3>
                    <div class="price-value">
                        <span class="currency">{{'expenses-sub-title' | transloco}}</span>
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i>{{'expenses-1' | transloco}}</li>
                        <li><i class="fas fa-check"></i>{{'expenses-2' | transloco}}</li>
                        <li><i class="fas fa-check"></i>{{'expenses-3' | transloco}}</li>
                        <li><i class="fas fa-check"></i>{{'expenses-4' | transloco}}</li>
                        <li><i class="fas fa-check"></i>{{'expenses-5' | transloco}}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-12">
            <span class="footnote">{{'pricing-footnote' | transloco}}</span>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="{{'shape-alt-text' | transloco}}"></div>
</div>
