<div class="why-we-different ptb-100 bg-F7F5F4" id="services">
    <div class="container">
        <div class="section-title">
            <h2 [innerHTML]="'services-title' | transloco"></h2>
        </div>
        <div class="tabset why-we-different-tabset">
            <!-- Tab 1 -->
            <input type="radio" name="tabset" id="tab1" aria-controls="whistleblower-management" checked>
            <label for="tab1">{{'whistleblower-management-tab' | transloco}}</label>
            <!-- Tab 2 -->
            <input type="radio" name="tabset" id="tab2" aria-controls="investigation-support">
            <label for="tab2">{{'investigation-support-tab' | transloco}}</label>
            <!-- Tab 3 -->
            <input type="radio" name="tabset" id="tab3" aria-controls="walkthrough">
            <label for="tab3">{{'walkthrough-tab' | transloco}}</label>
            <div class="tab-panels">
                <div id="whistleblower-management" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/hinweisgebermanagement.webp" alt="{{'whistleblower-management-tab' | transloco}}">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>{{'whistleblower-management-tab' | transloco}}</h3>
                                <p>{{'whistleblower-management-content' | transloco}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="investigation-support" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/ermittlungssupport.webp" alt="{{'investigation-support-tab' | transloco}}">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>{{'investigation-support-tab' | transloco}}</h3>
                                <p>{{'investigation-support-content' | transloco}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="walkthrough" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/komplettloesung.webp" alt="{{'walkthrough-tab' | transloco}}">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>{{'walkthrough-tab' | transloco}}</h3>
                                <p>{{'walkthrough-content' | transloco}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
