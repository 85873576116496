<div id="contact" class="contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 [innerHTML]="'contact-us-sub-title' | transloco"></h2>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <ng-container *ngIf="currentCookie === 'accepted';else noContactForm">
                        <form #contactForm="ngForm" (ngSubmit)="submit(contactForm)">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <label>{{'name-label' | transloco}}</label>
                                        <input required minlength="3" maxlength="50" ngModel name="name" type="text"
                                               #name="ngModel" class="form-control" id="name">
                                        <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                            <div
                                                *ngIf="name.errors.required">{{'validation-name-required' | transloco}}</div>
                                            <div
                                                *ngIf="name.errors.minlength">{{'validation-name-min-characters' | transloco}}</div>
                                            <div
                                                *ngIf="name.errors.maxlength">{{'validation-name-max-characters' | transloco}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <label>{{'mail-label' | transloco}}</label>
                                        <input required ngModel name="email" type="text" #email="ngModel"
                                               class="form-control" id="email">
                                        <div class="alert alert-danger"
                                             *ngIf="email.touched && !email.valid">{{'validation-mail-required' | transloco}}</div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <label>{{'subject-label' | transloco}}</label>
                                        <input required ngModel name="subject" type="text" #subject="ngModel"
                                               class="form-control" id="subject">
                                        <div class="alert alert-danger"
                                             *ngIf="subject.touched && !subject.valid">{{'validation-subject-required' | transloco}}</div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <label>{{'phone-label' | transloco}}</label>
                                        <input required ngModel name="number" type="text" #number="ngModel"
                                               class="form-control" id="number">
                                        <div class="alert alert-danger"
                                             *ngIf="number.touched && !number.valid">{{'validation-phone-required' | transloco}}</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <label>{{'message-label' | transloco}}</label>
                                        <textarea required ngModel #message="ngModel" name="message" id="message"
                                                  cols="30" rows="4" class="form-control"></textarea>
                                        <div class="alert alert-danger"
                                             *ngIf="message.touched && !name.valid">{{'validation-message-required' | transloco}}</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <label class="form-group-container">{{'accept-tac-label' | transloco}} <a
                                            routerLink="/datenschutz">{{'tac' | transloco}}</a>.
                                            <input required type="checkbox" ngModel #agb="ngModel" name="agb" id="agb"/>
                                            <span class="checkmark"></span>
                                            <div class="alert alert-danger"
                                                 *ngIf="agb.touched && !agb.valid">{{'validation-tac-required' | transloco}}</div>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid">
                                        Senden
                                    </button>
                                </div>

                            </div>
                        </form>
                        <div class="mail-response row align-items-center mt-3" [class.error]="showError" *ngIf="showMailResponse">
                            <i class="col-1 fa-2x fa-solid fa-check-circle" [class.fa-check-circle]="!showError"
                               [class.fa-exclamation-circle]="showError"></i>
                            <p class="col-11 mb-0">{{mailResponse}}</p>
                        </div>
                    </ng-container>
                    <ng-template #noContactForm>
                        <div>
                            <p>{{'contactform-no-cookies' | transloco}}</p>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="fas fa-map-marker-alt"></i> <span>{{'address-label' | transloco}}:</span>Wessenbergstrasse
                                    2, 78462 Konstanz, {{'germany' | transloco}}</li>
                                <li><i class="far fa-envelope"></i> <a
                                    href="mailto:&#097;&#110;&#119;&#097;&#108;&#116;&#064;&#108;&#097;&#119;&#045;&#122;&#097;&#104;&#110;&#101;&#114;&#046;&#100;&#101;"><span>{{'mail-label' | transloco}}
                                    :</span>anwalt@law-zahner.de</a></li>
                                <li><i class="fas fa-phone-alt"></i> <a
                                    href="tel:+497531128280"><span>{{'phone-label' | transloco}}:</span>+49 75 31/12 82
                                    80</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
