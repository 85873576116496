import { Component } from '@angular/core';

@Component({
  selector: 'app-subfooter',
  templateUrl: './subfooter.component.html',
  styleUrls: ['./subfooter.component.scss']
})
export class SubfooterComponent {

    today: Date = new Date()

}
